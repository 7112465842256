import {IonHeader, IonContent, IonToolbar, IonTitle, IonMenuToggle, IonItem, IonIcon, IonMenu, IonLabel, IonList, IonListHeader, IonGrid, IonAvatar, useIonRouter} from '@ionic/react';
import {useAppUtil, useSideMenuOptions} from "./SideMenuProvider";

import "../../theme/SideMenu.css";
import {ActionLink} from "../ActionLink";
import styles_TokenEnwEntrustListItem from "../TokenEnwEntrustListItem.module.scss";
import {cartOutline, diamondOutline, enterOutline, exitOutline, golfOutline, qrCodeOutline, receiptOutline, rocketOutline, settingsOutline, shieldCheckmarkSharp, terminalOutline} from "ionicons/icons";
import styles_2_1 from "../../pages/Pg_2_1.module.scss";
import Ln_TokenPictureSetCoverImg from "../../pages/Ln_TokenPictureSetCoverImg";
import {useRef, useState} from "react";
import {useStoreState} from "pullstate";
import {AuthStore, Logout} from "../../store/Common_Store";
import {getPageData} from "../../store/Selectors";
import {navToPg_1_1, navToPg_1_25, navToRoot} from "../../pages/Utils";
import {push_pg_A_1__Logout} from "../../store/Pg_A_Store";
import styles_Common from "../../pages/Common.module.scss";

const SideMenu = (props) => {
    const {type = "overlay"} = props;
    const children = props.children;
    const menuOptions: any = useSideMenuOptions();

    const router = useIonRouter();

    const appUtil = useAppUtil();
    (window as any).appUtil = appUtil;

    //#region push logout
    const push_logout = async () => {
        await appUtil.toast.present('登出中...', 10000)
        if (await push_pg_A_1__Logout({})) {
            await appUtil.toast.dismiss();
            await appUtil.toast.present('登出成功', 1000)
            navToPg_1_25(router);
        }
    }
    //#endregion

    ///#region 會員
    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;
    console.dir(Item_會員);
    ///#endregion

    //contentId={menuOptions.pageName}
    return (
        <IonMenu contentId={menuOptions.pageName} side={menuOptions.side} type={type} ref={appUtil.menu.elementRef}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>個人中心</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent forceOverscroll={false} id="main">
                {hasAuthed ? <>
                        <IonGrid>
                            <div className={`${styles_2_1.avatarContainer}`}>
                                <div>
                                    <IonAvatar className="avatar">
                                        {Ln_TokenPictureSetCoverImg({
                                            圖片組: Item_會員.個人照片,
                                            名稱: 'cover',
                                        })}
                                    </IonAvatar>
                                </div>
                                <div>
                                    <h6>{Item_會員.暱稱}{Item_會員.藍標 && <IonIcon icon={shieldCheckmarkSharp} className={styles_Common.userNameBlueIcon}></IonIcon>}</h6>
                                </div>
                            </div>

                            <IonList>
                                <IonItem lines="full">
                                    <ActionLink link={`/tabs/0_2`} className={styles_TokenEnwEntrustListItem.actionlink} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                {/*<IonAvatar className="avatar">*/}
                                                {/*    {Ln_TokenPictureSetCoverImg({*/}
                                                {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                                {/*        名稱: 'cover',*/}
                                                {/*    })}*/}
                                                {/*</IonAvatar>*/}
                                                <IonIcon icon={qrCodeOutline}></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                我的會員卡
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`/tabs/0_3`} className={styles_TokenEnwEntrustListItem.actionlink} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                {/*<IonAvatar className="avatar">*/}
                                                {/*    {Ln_TokenPictureSetCoverImg({*/}
                                                {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                                {/*        名稱: 'cover',*/}
                                                {/*    })}*/}
                                                {/*</IonAvatar>*/}
                                                <IonIcon icon={settingsOutline}></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                帳號設定
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                {/*<IonItem lines="full">*/}
                                {/*    <ActionLink link={`/tabs/0_18`} className={styles_TokenEnwEntrustListItem.actionlink} onClick={(e) => {*/}
                                {/*        appUtil.menu.elementRef.current.close()*/}
                                {/*    }}>*/}
                                {/*        <div className={styles_TokenEnwEntrustListItem.container}>*/}
                                {/*            <div className="ll">*/}
                                {/*                /!*<IonAvatar className="avatar">*!/*/}
                                {/*                /!*    {Ln_TokenPictureSetCoverImg({*!/*/}
                                {/*                /!*        圖片組: Item_會員公開__委託者.個人照片,*!/*/}
                                {/*                /!*        名稱: 'cover',*!/*/}
                                {/*                /!*    })}*!/*/}
                                {/*                /!*</IonAvatar>*!/*/}
                                {/*                <IonIcon icon={receiptOutline}></IonIcon>*/}
                                {/*            </div>*/}
                                {/*            <div className="l ll-1">*/}
                                {/*                收藏品交易紀錄*/}
                                {/*            </div>*/}
                                {/*            /!*<div className="r">*!/*/}
                                {/*            /!*    {Item_TokenEnwEntrust.價錢}寶石*!/*/}
                                {/*            /!*</div>*!/*/}
                                {/*        </div>*/}
                                {/*    </ActionLink>*/}
                                {/*</IonItem>*/}
                                {/*<IonItem lines="full">*/}
                                {/*    <ActionLink link={`/tabs/0_20`} className={styles_TokenEnwEntrustListItem.actionlink} onClick={(e) => {*/}
                                {/*        appUtil.menu.elementRef.current.close()*/}
                                {/*    }}>*/}
                                {/*        <div className={styles_TokenEnwEntrustListItem.container}>*/}
                                {/*            <div className="ll">*/}
                                {/*                /!*<IonAvatar className="avatar">*!/*/}
                                {/*                /!*    {Ln_TokenPictureSetCoverImg({*!/*/}
                                {/*                /!*        圖片組: Item_會員公開__委託者.個人照片,*!/*/}
                                {/*                /!*        名稱: 'cover',*!/*/}
                                {/*                /!*    })}*!/*/}
                                {/*                /!*</IonAvatar>*!/*/}
                                {/*                <IonIcon icon={diamondOutline}></IonIcon>*/}
                                {/*            </div>*/}
                                {/*            <div className="l ll-1">*/}
                                {/*                我的寶石*/}
                                {/*            </div>*/}
                                {/*            /!*<div className="r">*!/*/}
                                {/*            /!*    {Item_TokenEnwEntrust.價錢}寶石*!/*/}
                                {/*            /!*</div>*!/*/}
                                {/*        </div>*/}
                                {/*    </ActionLink>*/}
                                {/*</IonItem>*/}

                                <IonItem lines="full">
                                    <ActionLink link={`/`} className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        appUtil.menu.elementRef.current.close()
                                        push_logout();

                                        return false;
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                {/*<IonAvatar className="avatar">*/}
                                                {/*    {Ln_TokenPictureSetCoverImg({*/}
                                                {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                                {/*        名稱: 'cover',*/}
                                                {/*    })}*/}
                                                {/*</IonAvatar>*/}
                                                <IonIcon icon={exitOutline}></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                登出
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                            </IonList>
                        </IonGrid>

                        {/*{children}*/}

                        {/*<IonListHeader>{menuOptions.pageName}</IonListHeader>*/}

                        {/*{menuOptions !== null &&*/}
                        {/*    <IonList lines="none">*/}
                        {/*        {menuOptions && menuOptions.options.map((menuOption, i) => {*/}

                        {/*            if (menuOption.url === null) {*/}

                        {/*                return (*/}

                        {/*                    <IonMenuToggle key={i} autoHide={true}>*/}
                        {/*                        <IonItem onClick={menuOption.clickEvent} lines="none" detail={false}>*/}
                        {/*                            <IonIcon slot="start" icon={menuOption.icon}/>*/}
                        {/*                            <IonLabel>{menuOption.text}</IonLabel>*/}
                        {/*                        </IonItem>*/}
                        {/*                    </IonMenuToggle>*/}
                        {/*                );*/}
                        {/*            } else {*/}

                        {/*                if (menuOption.url !== null) {*/}
                        {/*                    return (*/}

                        {/*                        <IonMenuToggle key={i} autoHide={true}>*/}
                        {/*                            <IonItem detail={false} routerLink={menuOption.url} lines="none">*/}
                        {/*                                <IonIcon slot="start" icon={menuOption.icon}/>*/}
                        {/*                                <IonLabel>{menuOption.text}</IonLabel>*/}
                        {/*                            </IonItem>*/}
                        {/*                        </IonMenuToggle>*/}
                        {/*                    );*/}
                        {/*                }*/}
                        {/*            }*/}
                        {/*        })}*/}
                        {/*    </IonList>*/}
                        {/*}*/}


                        <IonList>
                            {Item_會員.會員Id == 18 && <>
                                <IonItem lines="full">
                                    <ActionLink link={`//openapi/1/cycleviewer?theme=light`} target="_self" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                <IonIcon src="/assets/icon/logo-instagram.svg"></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                [站長] Cycleviewer (白底)
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`//openapi/1/cycleviewer?theme=dark`} target="_self" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                <IonIcon src="/assets/icon/logo-instagram.svg"></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                [站長] Cycleviewer (黑底)
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                            </>}

                            {Item_會員.合約Id__限定處理兌換合約 !== null &&
                                <IonItem lines="full">
                                    <ActionLink link={`/tabs/0_24`} className={styles_TokenEnwEntrustListItem.actionlink} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                {/*<IonAvatar className="avatar">*/}
                                                {/*    {Ln_TokenPictureSetCoverImg({*/}
                                                {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                                {/*        名稱: 'cover',*/}
                                                {/*    })}*/}
                                                {/*</IonAvatar>*/}
                                                <IonIcon icon={terminalOutline}></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                商家作業
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                            }

                            <IonItem lines="full">
                                <ActionLink link={`https://www.emj.life`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                    appUtil.menu.elementRef.current.close()
                                }}>
                                    <div className={styles_TokenEnwEntrustListItem.container}>
                                        <div className="ll">
                                            {/*<IonIcon src="/assets/icon/logo-facebook.svg"></IonIcon>*/}
                                            <IonIcon icon={golfOutline}/>
                                        </div>
                                        <div className="l ll-1">
                                            Pet Journey 的使命
                                        </div>
                                        {/*<div className="r">*/}
                                        {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                        {/*</div>*/}
                                    </div>
                                </ActionLink>
                            </IonItem>
                            <IonItem lines="full">
                                <ActionLink link={`https://emj.life/emj-life-center`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                    appUtil.menu.elementRef.current.close()
                                }}>
                                    <div className={styles_TokenEnwEntrustListItem.container}>
                                        <div className="ll">
                                            {/*<IonIcon src="/assets/icon/logo-facebook.svg"></IonIcon>*/}
                                            <IonIcon icon={rocketOutline}/>
                                        </div>
                                        <div className="l ll-1">
                                            合作品牌
                                        </div>
                                        {/*<div className="r">*/}
                                        {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                        {/*</div>*/}
                                    </div>
                                </ActionLink>
                            </IonItem>
                            <IonItem lines="full">
                                <ActionLink link={`https://www.facebook.com/profile.php?id=61560563761778&mibextid=ZbWKwL`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                    appUtil.menu.elementRef.current.close()
                                }}>
                                    <div className={styles_TokenEnwEntrustListItem.container}>
                                        <div className="ll">
                                            <IonIcon src="/assets/icon/logo-facebook.svg"></IonIcon>
                                        </div>
                                        <div className="l ll-1">
                                            Facebook
                                        </div>
                                        {/*<div className="r">*/}
                                        {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                        {/*</div>*/}
                                    </div>
                                </ActionLink>
                            </IonItem>
                            <IonItem lines="full">
                                <ActionLink link={`https://www.instagram.com/pet.journey2023?igsh=dnZsNXA0MWlhZGR3`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                    appUtil.menu.elementRef.current.close()
                                }}>
                                    <div className={styles_TokenEnwEntrustListItem.container}>
                                        <div className="ll">
                                            <IonIcon src="/assets/icon/logo-instagram.svg"></IonIcon>
                                        </div>
                                        <div className="l ll-1">
                                            Instagram
                                        </div>
                                        {/*<div className="r">*/}
                                        {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                        {/*</div>*/}
                                    </div>
                                </ActionLink>
                            </IonItem>
                            <IonItem lines="full">
                                <ActionLink link={`https://www.threads.net/@pet.journey2023`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                    appUtil.menu.elementRef.current.close()
                                }}>
                                    <div className={styles_TokenEnwEntrustListItem.container}>
                                        <div className="ll">
                                            <IonIcon src="/assets/icon/logo-thread.svg"></IonIcon>
                                        </div>
                                        <div className="l ll-1">
                                            Thread
                                        </div>
                                        {/*<div className="r">*/}
                                        {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                        {/*</div>*/}
                                    </div>
                                </ActionLink>
                            </IonItem>
                            <IonItem lines="full">
                                <ActionLink link={`https://www.tiktok.com/@pet.journey?_t=8nsHMZAEjdU&_r=1`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                    appUtil.menu.elementRef.current.close()
                                }}>
                                    <div className={styles_TokenEnwEntrustListItem.container}>
                                        <div className="ll">
                                            <IonIcon src="/assets/icon/logo-tiktok.svg"></IonIcon>
                                        </div>
                                        <div className="l ll-1">
                                            TikTok
                                        </div>
                                        {/*<div className="r">*/}
                                        {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                        {/*</div>*/}
                                    </div>
                                </ActionLink>
                            </IonItem>
                            <IonItem lines="full">
                                <ActionLink link={`https://www.youtube.com/@petjourney_ai`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                    appUtil.menu.elementRef.current.close()
                                }}>
                                    <div className={styles_TokenEnwEntrustListItem.container}>
                                        <div className="ll">
                                            <IonIcon src="/assets/icon/logo-youtube.svg"></IonIcon>
                                        </div>
                                        <div className="l ll-1">
                                            Youtube
                                        </div>
                                        {/*<div className="r">*/}
                                        {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                        {/*</div>*/}
                                    </div>
                                </ActionLink>
                            </IonItem>
                            <IonItem lines="full">
                                <ActionLink link={`mailto://support@petjourney.ai`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                    appUtil.menu.elementRef.current.close()
                                }}>
                                    <div className={styles_TokenEnwEntrustListItem.container}>
                                        <div className="ll">
                                            <IonIcon src="/assets/icon/mail-outline.svg"></IonIcon>
                                        </div>
                                        <div className="l ll-1">
                                            客服信箱
                                        </div>
                                        {/*<div className="r">*/}
                                        {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                        {/*</div>*/}
                                    </div>
                                </ActionLink>
                            </IonItem>
                        </IonList>
                    </>
                    : <>

                        <IonGrid>
                            {/*<div className={`${styles_2_1.avatarContainer}`}>*/}
                            {/*    <div>*/}
                            {/*        <IonAvatar className="avatar">*/}
                            {/*            {Ln_TokenPictureSetCoverImg({*/}
                            {/*                圖片組: Item_會員.個人照片,*/}
                            {/*                名稱: 'cover',*/}
                            {/*            })}*/}
                            {/*        </IonAvatar>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <h6>{Item_會員.暱稱}</h6>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <IonList>
                                <IonItem lines="full">
                                    <ActionLink link={`/A_1`} className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                {/*<IonAvatar className="avatar">*/}
                                                {/*    {Ln_TokenPictureSetCoverImg({*/}
                                                {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                                {/*        名稱: 'cover',*/}
                                                {/*    })}*/}
                                                {/*</IonAvatar>*/}
                                                <IonIcon icon={enterOutline}></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                登入
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                            </IonList>


                            <IonList>
                                <IonItem lines="full">
                                    <ActionLink link={`https://www.emj.life`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                {/*<IonIcon src="/assets/icon/logo-facebook.svg"></IonIcon>*/}
                                                <IonIcon icon={golfOutline}/>
                                            </div>
                                            <div className="l ll-1">
                                                Pet Journey 的使命
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`https://emj.life/emj-life-center`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                {/*<IonIcon src="/assets/icon/logo-facebook.svg"></IonIcon>*/}
                                                <IonIcon icon={rocketOutline}/>
                                            </div>
                                            <div className="l ll-1">
                                                合作品牌
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`https://www.facebook.com/profile.php?id=61560563761778&mibextid=ZbWKwL`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                <IonIcon src="/assets/icon/logo-facebook.svg"></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                Facebook
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`https://www.instagram.com/pet.journey2023?igsh=dnZsNXA0MWlhZGR3`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                <IonIcon src="/assets/icon/logo-instagram.svg"></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                Instagram
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`https://www.threads.net/@pet.journey2023`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                <IonIcon src="/assets/icon/logo-thread.svg"></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                Thread
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`https://www.tiktok.com/@pet.journey?_t=8nsHMZAEjdU&_r=1`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                <IonIcon src="/assets/icon/logo-tiktok.svg"></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                TikTok
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`https://www.youtube.com/@petjourney_ai`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                <IonIcon src="/assets/icon/logo-youtube.svg"></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                Youtube
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                                <IonItem lines="full">
                                    <ActionLink link={`mailto://support@petjourney.ai`} target="_blank" className={styles_TokenEnwEntrustListItem.actionlink} noLink={false} onClick={(e) => {
                                        appUtil.menu.elementRef.current.close()
                                    }}>
                                        <div className={styles_TokenEnwEntrustListItem.container}>
                                            <div className="ll">
                                                <IonIcon src="/assets/icon/mail-outline.svg"></IonIcon>
                                            </div>
                                            <div className="l ll-1">
                                                客服信箱
                                            </div>
                                            {/*<div className="r">*/}
                                            {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                            {/*</div>*/}
                                        </div>
                                    </ActionLink>
                                </IonItem>
                            </IonList>
                        </IonGrid>
                    </>}
            </IonContent>
        </IonMenu>
    );
}

export default SideMenu;

import {IonCol, IonRouterLink, IonRow} from "@ionic/react";


type Props = {
    link: string
    target?:any
    children: any
    className?: string
    noLink?: boolean
    onClick?: any,
    onClickCapture?:any,
};
export const ActionLink = (props: Props) => {
    let {noLink = false} = props;

    if (noLink) {
        return <div className={`custom-link ${props.className ?? ""}`} onClickCapture={props.onClickCapture}>{props.children}</div>;
    } else {
        if(/(http(s?)):\/\//i.test(props.link) || /^[/][/]/i.test(props.link)) {
            let link = props.link.replace(/^[/][/]/, "/");
            return <a className={`custom-link ${props.className ?? ""}`} href={link} onClick={props.onClick} onClickCapture={props.onClickCapture} target={props.target}>{props.children}</a>
        } else if (/mailto:/i.test(props.link)) {
            let link = props.link;
            return <a className={`custom-link ${props.className ?? ""}`} href={link} onClick={props.onClick} onClickCapture={props.onClickCapture} target={props.target}>{props.children}</a>
        } else {
            return <IonRouterLink className={`custom-link ${props.className ?? ""}`} routerLink={props.link} onClick={props.onClick} onClickCapture={props.onClickCapture}>{props.children}</IonRouterLink>;
        }
    }
};
